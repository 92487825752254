<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Synchronisation des mails</v-card-title>
        <v-card-subtitle>Sélectionner les boites mails à synchroniser</v-card-subtitle>
        <v-card-text>
            <div>
                <v-text-field
                    class="ml-4 d-inline-block"
                    style="width:50%"
                    label="Entrez une adresse mail"
                ></v-text-field>
                <v-btn class="ml-2 d-inline-block" color="primary">+ Ajouter</v-btn>
            </div>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="(item, index) in sync_mail" :key="index">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-switch :input-value="item.enable" @change="() => item.enable = !item.enable" class="float-right" switch size="lg"/>
                    <v-btn icon class="ml-2" color="red">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Mails_sync',
    data() {
        return {
            sync_mail : JSON.parse(localStorage.getItem('settings')).preferencies.sync_mail,
            sync_mail_backup : JSON.parse(localStorage.getItem('settings')).preferencies.sync_mail,
        }
    },
    methods: {
        saveSettings() {
            if (JSON.stringify(this.sync_mail_backup) !== JSON.stringify(this.sync_mail)) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.preferencies.sync_mail = this.sync_mail;
                this.sync_mail_backup = JSON.parse(JSON.stringify(this.sync_mail));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.sync_mail = JSON.parse(localStorage.getItem('settings')).preferencies.sync_mail;
        },
        isChanges () {
            if (JSON.stringify(this.sync_mail_backup) !== JSON.stringify(this.sync_mail)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>
<style lang="">
    
</style>